/**
 * @module Main
 * @description
 */
import styles from './Main.module.css';

/**
 * @function Main
 * @description
 */
const Main = ( {
  children,
  className = ''
} ) => (
  <main
    role="main"
    className={ `
      ${ styles.main }
      ${ className }
    ` }
  >
    { children }
  </main>
);

export default Main;
