/**
 * @module Button
 * @description
 */
import Anchor from '@atoms/Typography/Anchor';
import useConditionalClasses from '@hooks/useConditionalClasses';
import styles from './index.module.css';

/**
 * @function Button
 * @description
 */
const Button = ( {
  children,
  className = '',
  type = 'button',
  disabled = false,
  primary, secondary, tertiary,
  small, medium, large,
  block,
  blockOnlyXS, blockOnlySM, blockOnlyMD, blockOnlyLG, blockOnlyXL,
  isLink,
  ...props
} ) => {
  const classes = {
    primary,
    secondary,
    tertiary,
    small,
    medium,
    large,
    block,
    blockOnlyXS,
    blockOnlySM,
    blockOnlyMD,
    blockOnlyLG,
    blockOnlyXL
  };
  const modifierClasses = useConditionalClasses( styles, classes );

  if ( isLink ) {
    return (
      <Anchor
        className={ `
          ${ styles.button }
          ${ modifierClasses }
          ${ className }
        ` }
        isButton
        { ...props }
      >{ children }</Anchor>
    );
  }

  return (
    <button
      className={ `
        ${ styles.button }
        ${ modifierClasses }
        ${ className }
      ` }
      type={ type }
      role="button"
      disabled={ disabled }
      { ...props }
    >
      { children }
    </button>
  );
};

export default Button;
