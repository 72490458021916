/**
 * @module Link
 * @description
 */
import Link from 'next/link';
import styles from './Anchor.module.css';

/**
 * @function Anchor
 * @description
 */
const Anchor = ( {
  children,
  href = '#',
  title,
  target,
  className,
  isButton
} ) => (
  <Link href={ href }>
    <a
      className={ `${ ! isButton ? styles.a : '' } ${ className }` }
      target={ target }
      title={ title }
    >
      { title || children }
    </a>
  </Link>
);

export default Anchor;
