/**
 * @module Paragraph
 * @description
 */
import styles from './Paragraph.module.css';

/**
 * @function Paragraph
 * @description
 */
const Paragraph = ( {
  children,
  className
} ) => (
 <p
  className={ `
    ${ styles.paragraph }
    ${ className }
  ` }
  >
    { children }
  </p>
);

export default Paragraph;
