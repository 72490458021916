/**
 * @module Footer Navigation
 * @description
 */
import Anchor from '@atoms/Typography/Anchor';
import Paragraph from '@atoms/Typography/Paragraph';
import Icon from '@atoms/Typography/Icon';
import styles from './FooterNavigation.module.css';

/**
 * @function FooterNavigation
 * @description
 */
const FooterNavigation = () => (
  <div className={ styles.info }>
    <div className={ styles.infoLeft }>
      <Paragraph className={ styles.infoItem }>
        <Icon icon="icon-user" />
        <span className="screen-reader-text">Game Designer</span>
        <span className={ styles.name }>Frank O'Brien</span>
      </Paragraph>
      <Paragraph className={ styles.infoItem }>
        <Icon icon="icon-phone" />
        <span className="screen-reader-text">Phone</span>
        <Anchor className={ styles.link } href="tel:2195610796" title="219.561.0796" />
      </Paragraph>
      <Paragraph className={ styles.infoItem }>
        <Icon icon="icon-envelope" />
        <span className="screen-reader-text">Email</span>
        <Anchor className={ styles.link } href="mailto:info.autojack21@yahoo.com" title="info.autojack21@yahoo.com" />
      </Paragraph>
    </div>

    <div className={ styles.infoRight }>
      <Paragraph className={ styles.copyright }>&copy; { new Date().getFullYear() }. O'Brien</Paragraph>
    </div>
  </div>
);

export default FooterNavigation;
