/**
 * @module Header
 * @description
 */
import styles from './Header.module.css';

/**
 * @function Header
 * @description
 */
const Header = ( {
  children,
  className = ''
} ) => (
  <header
    role="header"
    className={ `
      ${ styles.header }
      ${ className }
    ` }
  >
    { children }
  </header>
);

export default Header;
