/**
 * @module SiteNavigation
 * @description
 */
import Link from 'next/link';
import Image from 'next/image';
import { getAsset } from '@utils/helpers';
import styles from './HeaderNavigation.module.css';

/**
 * @function SiteNavigation
 * @description
 */
const SiteNavigation = () => (
  <div className={ styles.siteNavigation }>
    <Link href="/">
      <a className={ styles.logo }>
        <Image
          src={ getAsset( '/img/Logo.png' ) }
          alt="Autojack Blackjack Logo"
          width="175"
          height="182"
          loading="lazy"
        />
      </a>
    </Link>
  </div>
);

export default SiteNavigation;
