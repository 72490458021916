/**
 * @module Section
 * @description
 */
import styles from './index.module.css';

/**
 * @function Section
 * @description
 */
const Section = ( {
  children,
  className = ''
} ) => (
  <section
    className={ `
      ${ styles.section }
      ${ className }
    ` }
  >
    { children }
  </section>
);

export default Section;
