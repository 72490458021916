/**
 * @module Landing
 * @description
 */
import SiteHeader from '@organisms/Regions/SiteHeader';
import SiteFooter from '@organisms/Regions/SiteFooter';
import Container from '@atoms/Structures/Container';
import Main from '@atoms/Regions/Main';
import Heading from '@atoms/Typography/Heading';
import styles from './Landing.module.css';

/**
 * @function LandingTemplate
 * @description
 */
const LandingTemplate = ( {
  hero,
  main,
  title,
  modal
} ) => (
  <>
    <SiteHeader />

    { hero }

    <Container>
      <Main>
        <Heading className={ styles.title } level="1" content={ title } />
        { main }
      </Main>
    </Container>

    <SiteFooter />
    { modal }
  </>
);

export default LandingTemplate;
