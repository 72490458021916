/**
 * @module Panel
 * @description
 */
import Heading from '@atoms/Typography/Heading';
import styles from './index.module.css';

/**
 * @function Panel
 * @description
 */
const Panel = ( {
  children,
  className = '',
  title
} ) => (
  <div className={ `${ styles.panel } ${ className }` }>
    { title && <Heading level="2" content={ title } className={ styles.title } /> }

    <div className={ styles.content }>
      { children }
    </div>
  </div>
);

export default Panel;
